<template>
  <b-row class="mt-3" v-if="loading.empty">
  <b-button variant="primary" @click="fetchProfessionals" class="float-right">
      <span>Load Professionals</span>
  </b-button>
  </b-row>

  <b-row class="mt-3" v-else>
    <b-col cols="12">
      <b-table hover small responsive
               :items="professionalTableItems.items"
               :fields="professionalTableItems.fields"
               :busy="loading.professionals">
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner></b-spinner>
          </div>
        </template>
        <template #head(inInstitution)="scope">
          In Institution
          <small>
            ( <feather type="check-square" @click="toggleAllInInstitution(true)"/> /
            <feather type="square" @click="toggleAllInInstitution(false)"/> )
          </small>
        </template>
        <template #head(availableForOnboarding)="scope">
          Available For Onboarding
          <small>
            ( <feather type="check-square" @click="toggleAllAvailableForOnboarding(true)"/> /
            <feather type="square" @click="toggleAllAvailableForOnboarding(false)"/> )
          </small>
        </template>
        <template #cell(id)="professional">
          <router-link :to="{ name: 'ProfessionalEditDeprecated', params: { id: professional.item.id } }">{{ professional.item.id }}</router-link>
        </template>
        <template #cell(inInstitution)="professional">
          <input type="checkbox"
                 :disabled="!canBeAssociated(professional.item)"
                 v-model="professional.item.belongs_to_institution"
                 @change="toggleInstitution(professional)"/>
        </template>
        <template #cell(availableForOnboarding)="professional">
          <input type="checkbox"
                 :disabled="!canBeAvailableForOnboarding(professional.item)"
                 v-model="professional.item.available_for_onboarding"
                 @change="toggleInstitution(professional)"/>
                 <span :hidden="hasRequiredCredentials(professional.item)"
                    style="font-size: small" class="text-secondary">
                    PT does not have required credentials
                </span>
        </template>
      </b-table>
    </b-col>
    <b-col cols="12" class="d-flex justify-content-center">
      <b-pagination :disabled="loading.professionals"
                    @change="changePage"
                    v-model="currentPage"
                    :total-rows="total"
                    :per-page="itemsPerPage"
                    size="md">
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import { CLIENT_TYPE_BLOOM, CLIENT_TYPE_SWORD } from '@/scripts/constants';

export default {
  name: 'InstitutionProfessionalsTable',
  props: {
    type: { type: String },
    institution_id: { type: Number },
    institution: { type: Object },
    dpt_go_professional_id: { type: Number },
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      itemsPerPage: 20,
      professionals: [],
      loading: {
        empty: true,
        professionals: true,
      },
    };
  },
  computed: {
    professionalTableItems() {
      const fields = [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'total_active_patients', label: 'Active Members' },
        { key: 'type', label: 'Type' },
        { key: 'unit', label: 'Professional unit' },
        { key: 'inInstitution', label: 'In Institution' },
        { key: 'availableForOnboarding', label: 'Available For Onboarding' },
      ];
      const items = [];
      this.professionals.forEach(p => {
        if ((!p.intake_flow && !p.treatment_flow) || p.id === this.dpt_go_professional_id) {
          return;
        }
        items.push({
          id: p.id,
          name: `${p.firstname} ${p.lastname}`,
          total_active_patients: p.total_active_patients,
          type: this.professionalType(p),
          belongs_to_institution: p.belongs_to_institution,
          available_for_onboarding: p.available_for_onboarding,
          unit: p.unit,
          credential_group_ids: p.credential_group_ids,
          is_test: p.is_test,
        });
      });

      return { fields, items };
    },
  },
  methods: {
    professionalType(professional) {
      if (professional.intake_flow === 1 && professional.treatment_flow === 1) {
        return 'Treatment & Intake';
      }
      if (professional.intake_flow === 1) {
        return 'Intake';
      }
      if (professional.treatment_flow === 1) {
        return 'Treatment';
      }

      return '-';
    },
    toggleInstitution(event) {
      const foundProfessional = this.professionals.find(p => event.item.id === p.id);

      const isIntakeFlow = this.type === 'intake' || foundProfessional.intake_flow === 1;
      const isTreatmentFlow = this.type === 'treatment' || foundProfessional.treatment_flow === 1;

      const eventData = {
        id: foundProfessional.id,
        username: foundProfessional.username,
        firstname: foundProfessional.firstname,
        lastname: foundProfessional.lastname,
        available_for_onboarding: event.item.available_for_onboarding,
        belongs_to_institution: event.item.belongs_to_institution,
        total_active_patients: foundProfessional.total_active_patients,
        unit: foundProfessional.unit,
        credential_group_ids: foundProfessional.credential_group_ids,
        intake_flow: +isIntakeFlow,
        treatment_flow: +isTreatmentFlow,
      };

      this.$emit('toggle-professional-institution', eventData);
    },
    changePage(page) {
      this.currentPage = page;
      this.fetchProfessionals();
    },
    toggleAllAvailableForOnboarding(status) {
      this.professionals.forEach((_, k) => {
        if (this.canBeAvailableForOnboarding(this.professionals[k])) {
          this.professionals[k].available_for_onboarding = status;
          this.toggleInstitution({ item: this.professionals[k] });
        }
      });
    },
    toggleAllInInstitution(status) {
      this.professionals.forEach((_, k) => {
        if (this.canBeAssociated(this.professionals[k])) {
          this.professionals[k].belongs_to_institution = status;
          this.toggleInstitution({ item: this.professionals[k] });
        }
      });
    },
    fetchProfessionals() {
      this.loading.professionals = true;
      axios.get('v1/therapists/management', {
        params: {
          institution_id: this.institution_id,
          type: this.type,
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        },
      })
        .then(response => {
          this.total = response.data.total;
          this.professionals = response.data.professionals;
        })
        .catch(e => {
          console.error(e);
          Vue.prototype.$noty.error('Cannot request professionals', e);
        })
        .finally(() => {
          this.loading.empty = false;
          this.loading.professionals = false;
        });
    },
    canBeAssociated(professional) {
      return this.institution.ecosystems ? this.institution.ecosystems.map(e => e.unit).includes(professional.unit) : false;
    },
    hasRequiredCredentials(professional) {
      // if intake we don't care about credentials
      if (this.type === 'intake') {
        return true;
      }

      // new credential group id
      let credentialGroupId = null;
      if (this.institution.ecosystems && this.institution.ecosystems.length > 0) {
        const ecosystemsUnit = this.institution.ecosystems.find(e => e.unit === professional.unit);
        credentialGroupId = ecosystemsUnit ? ecosystemsUnit.credential_group_id : null;
      }

      // old credential group id
      if (!credentialGroupId && this.institution.config && this.institution.config.credential_group_id) {
        const oldInstitutionUnit = this.institution.config.is_bloom ? CLIENT_TYPE_BLOOM : CLIENT_TYPE_SWORD;
        if (oldInstitutionUnit === professional.unit) {
          credentialGroupId = this.institution.config.credential_group_id;
        }
      }

      return credentialGroupId ? professional.credential_group_ids.includes(credentialGroupId) : true;
    },
    canBeAvailableForOnboarding(professional) {
      if (!this.institution.is_test && professional.is_test) {
        return false;
      }

      return this.hasRequiredCredentials(professional) && professional.belongs_to_institution;
    },
  },
};
</script>
