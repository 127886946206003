<template>
  <b-container class="mt-4">
    <div>
      <b-card :title=pageTitle>
        <b-card-text v-if="!loading.institutions">
          <b-row class="mt-3">
            <b-col cols="6">
              <b-button @click="$bvModal.show('modal-review-and-apply')">
                Review and apply changes
                <b-badge variant="light">{{ actions.length }}</b-badge>
              </b-button>
            </b-col>
          </b-row>
        </b-card-text>
        <b-card-text v-else>
          <b-spinner></b-spinner>
        </b-card-text>
      </b-card>
    </div>

    <div class="mt-3">
      <b-tabs content-class="mt-3">
        <b-tab title="Intake" active>
          <InstitutionProfessionalsTable
            :institution_id="institution_id"
            :institution="institution"
            :dpt_go_professional_id="dptGoProfessionalId"
            type="intake"
            @toggle-professional-institution="toggleInstitution"/>
        </b-tab>
        <b-tab title="Treatment">
          <InstitutionProfessionalsTable
            :institution_id="institution_id"
            :institution="institution"
            :dpt_go_professional_id="dptGoProfessionalId"
            type="treatment"
            @toggle-professional-institution="toggleInstitution"/>
        </b-tab>
      </b-tabs>
    </div>

    <b-modal id="modal-review-and-apply" size="xl" hide-footer>
      <template #modal-title>
        Review and apply changes
      </template>

      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th>#</b-th>
            <b-th>Name</b-th>
            <b-th>Institution</b-th>
            <b-th>Onboarding</b-th>
            <b-th>Action</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="item in actions" :key="item.id">
            <b-td>{{ item.professional.id }}</b-td>
            <b-td>{{ item.professional.firstname }} {{ item.professional.lastname }}</b-td>
            <b-td>{{ boolToEnglish(item.professional.belongs_to_institution) }}</b-td>
            <b-td>{{ boolToEnglish(item.professional.available_for_onboarding) }}</b-td>
            <b-td class="text-center">
              <button @click="saveProfessional(item)" v-if="item.status === 'pending'">Save</button>
              <b-spinner v-if="item.status === 'loading'"></b-spinner>
              <feather class="text-success" type="check-circle" v-if="item.status === 'done'"></feather>
              <div v-if="item.status === 'error'">
                <feather class="text-danger" type="x-circle" v-if="item.status === 'done'"></feather>
                <button @click="saveProfessional(item)">Retry</button>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

    </b-modal>
  </b-container>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import InstitutionProfessionalsTable from '@/views/institutions/partial/InstitutionProfessionalsTable.vue';

export default {
  name: 'InstitutionProfessionals',
  components: {
    InstitutionProfessionalsTable,
  },
  data() {
    return {
      institution_id: parseInt(this.$route.params.institutionID, 10),
      institution: {},
      actions: [],
      currentPage: 1,
      total: 0,
      itemsPerPage: 50,
      professionals: [],
      loading: {
        institution: true,
      },
      dptGoProfessionalId: 0,
    };
  },
  computed: {
    pageTitle() {
      if (!this.institution) {
        return 'Institution professionals';
      }
      return `Institution professionals: ${this.institution.name}`;
    },
  },
  methods: {
    boolToEnglish: value => (value ? 'Yes' : 'No'),
    setActionStatus(action, status) {
      for (let i = 0; i < this.actions.length; i++) {
        if (this.actions[i].professional.id === action.professional.id) {
          Vue.set(this.actions, i, {
            professional: action.professional,
            status,
          });
        }
      }
    },
    toggleInstitution(event) {
      const professionalID = event.id;
      let actionFound = false;
      Object.keys(this.actions).forEach(k => {
        if (this.actions[k].professional.id === professionalID) {
          Vue.set(this.actions, k, {
            professional: event,
            status: 'pending',
          });
          actionFound = true;
        }
      });
      if (actionFound) {
        return;
      }

      this.actions.push({
        professional: event,
        status: 'pending',
      });
    },
    fetchInstitution() {
      return axios.get(`/v2/institutions/${this.institution_id}`, {
        params: { client: 'web' },
      })
        .then(response => {
          this.institution = response.data.data;
        })
        .catch(e => {
          console.error(e);
          Vue.prototype.$noty.error('Cannot request professionals', e);
        })
        .finally(() => {
          this.loading.institution = false;
        });
    },
    saveProfessional(action) {
      this.setActionStatus(action, 'loading');

      const institutions = {};
      institutions[this.institution_id] = {
        enabled: action.professional.belongs_to_institution,
        available_for_onboarding: action.professional.available_for_onboarding,
      };

      axios.put(`v1/users/${action.professional.id}`, {
        intake_flow: action.professional.intake_flow ? 1 : 0,
        treatment_flow: action.professional.treatment_flow ? 1 : 0,
        institutions,
      })
        .then(r => {
          if (r.data?.error && r.data.error === true) {
            console.error(r.data?.message);
            Vue.prototype.$noty.error(`Failed to save therapist ${action.professional.id}: ${r.data?.message}`);
            this.setActionStatus(action, 'error');
            return;
          }

          this.setActionStatus(action, 'done');
          Vue.prototype.$noty.success(`${action.professional.firstname} ${action.professional.lastname} updated`);
        })
        .catch(e => {
          console.error(e);
          Vue.prototype.$noty.error('Failed to save therapist', e);
          this.setActionStatus(action, 'error');
        });
    },
  },
  async mounted() {
    this.$store.dispatch('Config/fetchConfigs').then(configs => {
      const dptConfig = configs.find(e => e.key === 'dpt_go_default_pt_id');
      this.dptGoProfessionalId = dptConfig?.value;
      this.fetchInstitution();
    });
  },
};
</script>
